module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"dragon-tree-travel","short_name":"dtt","start_url":"/","background_color":"#F9F5F2","theme_color":"#FC6621","display":"minimal-ui","icon":"src/images/tree-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4d0efcb27f034684163815ccf85fda3d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/Users/Asia/DEV/dragon-tree-travel/src/intl","languages":["pl"],"defaultLanguage":"pl","redirect":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
